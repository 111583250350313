import React from "react"
import { useQuery } from "@apollo/react-hooks"
import renderHTML from "react-render-html"
import { FlexboxGrid, Col, Container, Header, Content, List } from "rsuite"
import Helmet from "react-helmet"

import GET_ACTEURS from "../queries/getActeurs.graphql"

import { LazyImage } from "../components/LazyImage"
import CustomMenu from "../components/custommenu"
import SEO from "../components/seo"
import TopHeader from "../components/topHeader"
import FooterSection from "../components/footer"

import "../styles/global.css"

const ActeursPage = () => {
  const { loading, error, data } = useQuery(GET_ACTEURS)
  if (loading) return null
  if (error) return `Error! ${error}`
  //Deconstruct the query
  const {
    lesActricesEtActeursTitreEtDescription,
    ficheActeur,
    actorpageProduction,
    actorpageIlsontaime,
  } = data.pageBy.acfActor

  // Map title and text from the query
  const lesActricesEtActeursTitreEtDescriptionData = lesActricesEtActeursTitreEtDescription.map(
    item => {
      let data = {
        titre: item.actorpageTitre,
        texte: item.actorpageDescription,
      }
      return data
    }
  )

  // Create component and render the title and text
  const ListTitleAndText = tts => {
    return tts.map((tt, i) => (
      <div key={i}>
        {tt.titre && <h2 className="acteur-titre">{tt.titre}</h2>}
        <br />
        <div className="acteur-texte">{tt.texte && renderHTML(tt.texte)}</div>
      </div>
    ))
  }

  // Map actors data from the query
  const ficheActeurData = ficheActeur.map(item => {
    let data = {
      nom: item.actorpageActeursNom,
      role: item.actorpageActeursRole,
      photo: item.actorpageActeursPhoto.mediaItemUrl,
    }
    return data
  })

  // Create component and render the actor's data
  const ListActors = acteurs => {
    return (
      <>
        {acteurs.length > 4 ? (
          <>
            <FlexboxGrid justify="center" className="acteur-row">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <FlexboxGrid justify="center">
                  {acteurs.map(
                    (acteur, i) =>
                      i < 4 && (
                        <FlexboxGrid.Item
                          key={i}
                          componentClass={Col}
                          colspan={24}
                          md={6}
                          xs={24}
                          className="block-acteur"
                        >
                          <div className="wrap-acteur">
                            <LazyImage
                              alt="img"
                              src={acteur.photo}
                              width="100%"
                            />

                            <h5 className="acteur-nom">{acteur.nom}</h5>
                            <p className="acteur-role">Rôle : {acteur.role}</p>
                          </div>
                        </FlexboxGrid.Item>
                      )
                  )}
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center" className="acteur-row">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <FlexboxGrid justify="center">
                  {acteurs.map(
                    (acteur, i) =>
                      i > 3 &&
                      i < 8 && (
                        <FlexboxGrid.Item
                          key={i}
                          componentClass={Col}
                          colspan={24}
                          md={6}
                          xs={24}
                          className="block-acteur"
                        >
                          <div className="wrap-acteur">
                            <LazyImage
                              alt="img"
                              src={acteur.photo}
                              width="100%"
                            />
                            <h5 className="acteur-nom">{acteur.nom}</h5>
                            <p className="acteur-role">Rôle : {acteur.role}</p>
                          </div>
                        </FlexboxGrid.Item>
                      )
                  )}
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center" className="acteur-row">
              {acteurs.map(
                (acteur, i) =>
                  i > 7 &&
                  i < 10 && (
                    <FlexboxGrid.Item
                      key={i}
                      componentClass={Col}
                      colspan={24}
                      md={6}
                      xs={24}
                      className="block-acteur"
                    >
                      <div className="wrap-acteur">
                        <LazyImage alt="img" src={acteur.photo} width="100%" />
                        <h5 className="acteur-nom">{acteur.nom}</h5>
                        <p className="acteur-role">Rôle : {acteur.role}</p>
                      </div>
                    </FlexboxGrid.Item>
                  )
              )}
            </FlexboxGrid>
          </>
        ) : (
          <FlexboxGrid justify="center" className="acteur-row">
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={18} xs={24}>
              <FlexboxGrid justify="center">
                {acteurs.map(
                  (acteur, i) =>
                    i < 3 && (
                      <FlexboxGrid.Item
                        key={i}
                        componentClass={Col}
                        colspan={24}
                        md={6}
                        xs={24}
                        className="block-acteur"
                      >
                        <div className="wrap-acteur">
                          <img alt="img" src={acteur.photo} width="100%" />
                          <h5>{acteur.nom}</h5>
                          <p>Rôle : {acteur.role}</p>
                        </div>
                      </FlexboxGrid.Item>
                    )
                )}
              </FlexboxGrid>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        )}
      </>
    )
  }

  // Map Title and texte from the query of IlsOntAimes data
  const lesActricesEtActeursIlsOntAimesData = actorpageIlsontaime.map(item => {
    let data = {
      titre: item.actorpageIlsontaimeTitre,
      texte: item.actorpageIlsontaimeTexte,
      sousTexte: item.actorpageIlsontaimeSoustexte.map(texte => {
        let ilsontaimeData = {
          sousTexteTitre: texte.actorpageIlsontaimeSoustexteTitre,
          sousTexte: texte.actorpageIlsontaimeSoustexteTexte,
        }
        return ilsontaimeData
      }),
    }
    return data
  })

  // Construct object for Technician
  const IlsOntAime = () => {
    let data = []
    lesActricesEtActeursIlsOntAimesData.map(item => {
      return (
        item &&
        item.sousTexte &&
        item.sousTexte.map(sousTextes => {
          return data.push({
            sousTitre: sousTextes.sousTexteTitre,
            sousTexte: sousTextes.sousTexte,
          })
        })
      )
    })
    return (
      <>
        <FlexboxGrid justify="center" className="liked">
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={18}
            xs={24}
            className="love_titre"
          >
            <h2>
              {lesActricesEtActeursIlsOntAimesData[0] &&
                lesActricesEtActeursIlsOntAimesData[0].titre &&
                lesActricesEtActeursIlsOntAimesData[0].titre}
            </h2>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid justify="center" className="liked">
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={18}
            xs={24}
            className="love-soustexte"
          >
            {lesActricesEtActeursIlsOntAimesData[0] &&
              lesActricesEtActeursIlsOntAimesData[0].texte &&
              renderHTML(lesActricesEtActeursIlsOntAimesData[0].texte)}
            <br />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {data.map((result, i) => (
          <FlexboxGrid key={i} justify="center" className="liked">
            <FlexboxGrid.Item
              componentClass={Col}
              colspan={24}
              md={18}
              xs={24}
              className=""
            >
              <h5 className="love-soustitre">{result.sousTitre}</h5>
              <div className="love-soustexte">
                {renderHTML(result.sousTexte)}
              </div>
              <br />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        ))}
      </>
    )
  }

  // Map Title and texte from the query of production data
  const actorpageProductionData = actorpageProduction.map(item => {
    let data = {
      titre: item.actorpageProductionTitre,
      texte: item.actorpageProductionTexte,
      technicien: item.actorpageProductionTechniciens.map(technicien => {
        let technicienData = {
          categorie: technicien.actorpageProductionTechniciensCategorie,
          metier: technicien.actorpageProductionTechniciensMetier,
          nom: technicien.actorpageProductionTechniciensNom,
        }
        return technicienData
      }),
    }
    return data
  })

  // Construct object for Technician
  const RenderTechnicienLeft = () => {
    let data = []
    actorpageProductionData.map(item => {
      console.log("item", item)
      return (
        item &&
        item.technicien &&
        item.technicien
          .filter(tech => tech.categorie === "PRODUCTION")
          .map(technicien => {
            return data.push({
              categorie: technicien.categorie,
              metier: technicien.metier,
              nom: technicien.nom,
            })
          })
      )
    })
    return (
      <>
        <h6 className="headerlist">
          {data && data[0].categorie && data[0].categorie}
        </h6>
        <List className="listTechnician">
          {data.map((result, i) => (
            <List.Item key={i}>
              <span className="work">
                {result && result.metier && result.metier}
              </span>
              <span className="name">{result && result.nom && result.nom}</span>
            </List.Item>
          ))}
        </List>
      </>
    )
  }

  const RenderTechnicienRight = () => {
    let data = []
    actorpageProductionData.map(item => {
      return (
        item &&
        item.technicien &&
        item.technicien
          .filter(tech => tech.categorie === "ACTRICES ET ACTEURS")
          .map(technicien => {
            return data.push({
              categorie: technicien.categorie,
              metier: technicien.metier,
              nom: technicien.nom,
            })
          })
      )
    })

    return (
      <>
        <h6 className="headerlist">
          {data && data[0].categorie && data[0].categorie}
        </h6>
        <List className="listTechnician">
          {data.map((result, i) => (
            <List.Item key={i}>
              <span className="work">
                {result && result.metier && result.metier}
              </span>
              <span className="name">{result && result.nom && result.nom}</span>
            </List.Item>
          ))}
        </List>
      </>
    )
  }

  // Create component and render the production technicians
  const BlocProduction = () => (
    <>
      <br />
      <h2 className="production-titre">
        {actorpageProductionData[0] &&
          actorpageProductionData[0].titre &&
          actorpageProductionData[0].titre}
      </h2>
      <br />
      <div className="production-texte">
        {actorpageProductionData[0] &&
          actorpageProductionData[0].texte &&
          renderHTML(actorpageProductionData[0].texte)}
      </div>
    </>
  )

  // Render
  return (
    <>
      <SEO
        title="Les Acteurs et les Actrices"
        keywords={[`Les Acteurs et les Actrices de la série Bonnes Nouvelles`]}
      />
      <Helmet>
        <title>Les Acteurs et les Actrices</title>
        <meta name="description" content="Les Acteurs et les Actrices" />
      </Helmet>
      <CustomMenu />
      <Container>
        <Header>
          <div className="block-header">
            <TopHeader />
          </div>
        </Header>
        <Content id="page-wrap">
          <div className="content">
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                {ListTitleAndText(lesActricesEtActeursTitreEtDescriptionData)}
              </FlexboxGrid.Item>
            </FlexboxGrid>
            {ListActors(ficheActeurData)}
            <IlsOntAime />
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
                className="production-bloc"
              >
                <BlocProduction />
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md="10"
                    xs={24}
                  >
                    <RenderTechnicienLeft />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md="10"
                    xs={24}
                  >
                    <RenderTechnicienRight />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default ActeursPage
